import React from 'react';
import _ from 'lodash';

import {htmlToReact, getPages, Link, withPrefix} from '../utils';
import BlogPostFooter from './BlogPostFooter';

export default class SectionPosts extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        let display_posts = _.orderBy(getPages(this.props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
        let recent_posts = display_posts.slice(0, 3);
        return (
            <section id={_.get(section, 'section_id', null)} className={'block posts-block bg-' + _.get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              <div className="inner">
                <div className="grid post-feed">
                  <article class="cell post">
                    <div class="card">
                      <a class="post-thumbnail" href="https://sal-tech.com/en/blog/news/semi-automatic-strapping-machine-and-there-uses" target="_blank"><img src="/images/10.jpeg" alt="A group of people working in a cafe"/></a>
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Semi-Automatic Strapping Machine and There uses </h3>
                          </header>
                          <div class="post-excerpt"><p>Strapping Machine are used to make Packages, Bundles, Metal tubes and Boxes held tight strongly together. This machine has materials of rolls called Strapping that is dispensed to seal around the Packages, Bundles, Metal tubes and Boxes.</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <a class="post-thumbnail" href="https://sal-tech.com/en/blog/news/factors-for-buying-manual-hand-strapping-or-strapping-machine" target="_blank"><img src="/images/9.jpeg" alt="A group of people working in a cafe"/></a>
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Factors for Buying Manual Hand Strapping or Strapping Machine</h3>
                          </header>
                          <div class="post-excerpt"><p>Why do we need strapping machines when manual hand strapping tools are so cheap? Are strapping tools cheaper? Definitely yes but the question is which tool or machine do you really need to use for the type of application you have on your business?</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <a class="post-thumbnail" href="https://sal-tech.com/en/blog/news/what-you-need-to-know-about-e3-wrap-stretch-film" target="_blank"><img src="/images/8.jpeg" alt="A group of people working in a cafe"/></a>
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">What You Need To Know About E3 Wrap Stretch Film </h3>
                          </header>
                          <div class="post-excerpt"><p>Stretch film is commonly used to wrap products on pallets and secure them to each other and the pallet. This often helps to reduce products loss, discourage load tampering, and reduce worker injury. In order for us to determine the right supplier</p></div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </section>
        );
    }
}
