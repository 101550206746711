import React from 'react';
import _ from 'lodash';

import {htmlToReact, classNames, markdownify} from '../utils';
import CtaButtons from './CtaButtons';

export default class SectionPricing extends React.Component {
    render() {
        let section = _.get(this.props, 'section', null);
        return (
            <section id={_.get(section, 'section_id', null)} className={'block pricing-block bg-' + _.get(section, 'background', null) + ' outer'}>
              <div className="block-header inner-small">
                {_.get(section, 'title', null) && (
                <h2 className="block-title">{_.get(section, 'title', null)}</h2>
                )}
                {_.get(section, 'subtitle', null) && (
                <p className="block-subtitle">
                  {htmlToReact(_.get(section, 'subtitle', null))}
                </p>
                )}
              </div>
              <div className="inner">
                <b>Hallbrookdesign.com business mission today same as SAL-TECH Business Mission then:</b>
                <p>To engineer and trade products of a wide nature always within the highest set of standards, especially when it concerns environment, moral, quality, care to human resources, prestige and price. The connection between Hallbrookdesign.com business mission and the environment, moral, quality, care to human resources, prestige and price can be outlined as follows:</p>
                <div className="grid post-feed">
                  <article class="cell post">
                    <div class="card">
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Environment</h3>
                          </header>
                          <div class="post-excerpt"><p>Any product engineered or sold on any world market shall be a product providing the best conditions for the environment and Hallbrookdesign.com shall always try to improve these conditions, and thereby be market leader in that respect.</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Moral</h3>
                          </header>
                          <div class="post-excerpt"><p>Any actions taken by Hallbrookdesign.com be within common moral sets, adapted to the product and the market in question.</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Quality</h3>
                          </header>
                          <div class="post-excerpt"><p>The products of Hallbrookdesign.com shall be within the market range they access, giving good performance, high reliability and productivity. Special care shall always be taken towards meeting needed safety requirements.</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Care To Human Resource</h3>
                          </header>
                          <div class="post-excerpt"><p>No task within the organisation or by external supplier must stress or damage human health. All management tools must secure the possible care to the people employed, and that information is forwarded on a nice to know basis, whenever possible.</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Prestige</h3>
                          </header>
                          <div class="post-excerpt"><p>The price obtained for the products shall always be above the average of the market, never undercutting to obtain orders and at the same time not taking overprice, as if a monopolistic situation is present.</p></div>
                      </div>
                    </div>
                  </article>
                  <article class="cell post">
                    <div class="card">
                      <div class="post-body">
                          <header class="post-header">
                              <h3 class="post-title">Achieving Our Goals</h3>
                          </header>
                          <div class="post-excerpt"><p>For each product, a specific range of sub-parameters in conjunction with the above listed goals will be applied and must be evaluated before going into the market. Our success is equal to our ability to achieve these goals.</p></div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              
            </section>
        );
    }
}
